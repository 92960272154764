import React, { useEffect, useState, useRef } from "react";

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import HelpIcon from '@mui/icons-material/Help';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';

import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import VChatAction from "./VChatAction";
import VChatCompanyList from "./VChatCompanyList";
import VChatMessageItem from "./VChatMessageItem";
import VChatDocsList from "./VChatDocsList";
import VChatReportSummary from "./VChatReportSummary";

import ChatFaqModal from "modals/chatFaqModal";
import ChatSupportModal from "modals/chatSupportModal";


const VChat = (props) =>{
    const [selected, setSelected] = useState(null);
    const selectedRef = useRef(selected);
    
    const [isNewMessage, setIsNewMessage] = useState(false);
    const [chatFaqModal, setChatFaqModal] = useState(false);
    const [chatSupportModal, setChatSupportModal] = useState(false);

    const [chatHistory, setChatHistory] = useState([]);
    const [responseChatArchive, setResponseChatArchive] = useState([]);
    const [data, setData] = useState([]);

    const MOCK_CLOSE_LOOP_ACTION_CHAT = {
        content: "Bantu AI untuk validasi tipe dokumen yang Anda upload dengan memilih pilihan berikut : ",
        action: [
            { 
                type: "button",
                label: "KTP",
                value: "ktp"
            },
            {
                type: "button",
                label: "NPWP",
                value: "npwp"
            }
        ],
        user_id: 0,
        time: "2024-07-15T09:30:22.159957",
        room_id: selectedRef.current ? 0 : selectedRef.current?.room_id
    }

    useEffect(() => {
        props.socket.on('data', (data) => {
            if (data.room_id === selectedRef.current.room_id) {
                const newMessage = {
                    content: data.message,
                    created_at: data.time,
                    user_id: data.author_id,
                    action: data.action
                }
                setChatHistory([...chatHistory, newMessage]);
                
            }
        })

        return () => {
            props.socket.off('data');
        }
    })

    useEffect(() => {
        if (selected) {
            // Propose to have rest api to get chat history, since the history 
            // is on the websocket join event, it always need to emit join everytime 
            // user click company on the list
            // getChat(); 
            selectedRef.current = selected;
        }
    }, [selected])

    const handleSelectChatList = async(item, type) => {
        try {
            // emit join
            const payload = {
                partner_id: item.id
            }
            props.socket.emit('join', payload)

            props.socket.on('join', (data) => {
                if (data.room_id) {
                    const currentSelectedWithRoomId = {
                        id: item.id,
                        name: item.name,
                        room_id: data.room_id,
                        type: type
                    }
                    setSelected(currentSelectedWithRoomId)
                    if (data.archive.length) {
                        const dataChat = data.archive.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1)
                        setChatHistory(dataChat)
                    } else {
                        setChatHistory([])
                    }
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleSelectDocumentList = async(item, type) => {
        const currentSelected = {
            id: item.id,
            name: item.name,
            type: type
        }
        setSelected(currentSelected)
    }

    const handleSelectReportList = async(item, type) => {
        const currentSelected = {
            id: item.id,
            name: item.name,
            type: type
        }
        setSelected(currentSelected)
    }

    const handleOpenModalFaq = () => {
        setChatFaqModal(true);
    };

    const handleOpenModalSupport = () => {
        setChatSupportModal(true);
    };

    const getChat = async () => {
        //  
    }

    const sendChat = async (text) => {
        if (text != "") {
            const newMessage = {
                message: text,
                room_id: selected.room_id,
                partner_id: selected.id
            }
        
            props.socket.emit('data', newMessage);
        }   
    }
    
    const sendAction = async (value) => {
        if (value != "") {
            const newMessage = {
                message: value,
                room_id: selected.room_id,
                partner_id: selected.id
            }
        
            props.socket.emit('data', newMessage);
        }   
    }

    return (
        <Grid container spacing={2} sx={{px:2, py:2}}>
            <Grid item lg={3}>
                <MDBox 
                    sx={{
                    px: 2,
                    py: 2,
                    height: "85vh",
                    bgcolor: "rgba(255, 255, 255, 1)",
                        borderRadius: "16px",
                        border: "1px solid #e1e1e1",
                        fontSize: "small",
                        boxShadow: "10px 15px 27px -28px #1A73E8",
                    }}
                >
                    <MDInput sx={{ width: "100%", marginBottom: 2 }} placeholder="cari perusahaan..." />
                    <List
                        sx={{ width: '100%', overflowY: 'auto', maxWidth: 360 }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {props.companyList.map((item, i) => (
                            <VChatCompanyList 
                                key={item.id}
                                data={item}
                                handleSelect={() => handleSelectChatList(item, "chat")}
                                handleSelectDocs={() => handleSelectDocumentList(item, "docs")}
                                handleSelectReport={() => handleSelectReportList(item, "report")}
                                selected={selected}
                            />
                        ))}
                    </List>
                </MDBox>
            </Grid>
            <Grid item lg={9}>
                { 
                    selected !== null 
                    ? 
                        selected.type === "chat" 
                        ?
                            <>
                                <MDBox 
                                    sx={{
                                        position: "relative",
                                        px: 2,
                                        py: 2,
                                        height: "85vh",
                                        bgcolor: "rgba(255, 255, 255, 1)",
                                        borderRadius: "16px",
                                        border: "1px solid #e1e1e1",
                                        fontSize: "small",
                                        boxShadow: "10px 15px 27px -28px #1A73E8"
                                    }}
                                >
                                    <MDBox key={1} display="flex" alignItems="center" py={2}>
                                        <MDBox mr={2}>
                                            {/* <MDAvatar src={""} alt="" shadow="md" /> */}
                                            <AccountCircleOutlinedIcon 
                                                fontSize="large"
                                                color="primary"
                                            />
                                        </MDBox>
                                        <MDBox display="flex" sx={{  width: "100%" }}>
                                            <MDTypography 
                                                variant="button" 
                                                fontWeight="bold" 
                                                justifyContent="flex-start"
                                                textTransform="capitalize">
                                                Kentara AI
                                            </MDTypography>
                                            <MDBox display="flex" ml={"auto"} sx={{ width: "30%" }}>
                                                <MDBox 
                                                    display="flex"
                                                    sx={{ width: "50%", justifyContent: "center" }}
                                                    onClick={() => handleOpenModalSupport()}
                                                >
                                                    <HelpIcon
                                                        fontSize="medium"
                                                        color="primary"
                                                    />
                                                    <MDTypography  variant="button" fontWeight="bold" justifyContent="flex-end" textTransform="capitalize" pl={2}>
                                                        Bantuan
                                                    </MDTypography>
                                                </MDBox>
                                                <Divider 
                                                    orientation="vertical"
                                                />
                                                <MDBox 
                                                    display="flex"
                                                    sx={{ width: "50%", justifyContent: "center" }}
                                                    onClick={() => handleOpenModalFaq()}
                                                >
                                                    <MenuIcon 
                                                        fontSize="medium"
                                                        color="primary"
                                                    />
                                                    <MDTypography  variant="button" fontWeight="bold" justifyContent="flex-end" textTransform="capitalize" pl={2}>
                                                        FAQ
                                                    </MDTypography>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                    <Divider />
                                    <MDBox sx={{  height: "auto" }}
                                    >
                                        <MDBox sx={{ 
                                            position: "relative",
                                            height: "60vh",
                                            pb: 2,
                                            pt: 2,
                                            pr:1,
                                            overflow: "auto"
                                        }}>
                                            {chatHistory.map((item, i) => (
                                                <VChatMessageItem item={item} key={i} chatHistory={chatHistory} handleSendAction={sendAction}  />
                                            ))}
                                        </MDBox>
                                    </MDBox>
                                    <MDBox sx={{ 
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    px:2,
                                    marginBottom: 2 }}
                                    >
                                        <VChatAction
                                            handleGet={getChat}
                                            handleSend={sendChat}
                                            selected={selected}
                                        />
                                    </MDBox>
                                </MDBox>
                            </>   
                        : 
                            selected.type === "docs"
                            ?
                                <MDBox 
                                    sx={{
                                        display: "flex",
                                        width: "100%",
                                        height: "100%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor: "rgba(255, 255, 255, 1)",
                                        borderRadius: "16px",
                                        border: "1px solid #e1e1e1",
                                        boxShadow: "10px 15px 27px -28px #1A73E8"
                                    }}
                                >
                                    <VChatDocsList
                                        key={selectedRef.current.id}
                                        data={selectedRef.current}
                                    />
                                </MDBox>
                            : 
                                selected.type === "report"
                                ?
                                    <MDBox 
                                        sx={{
                                            display: "flex",
                                            width: "100%",
                                            height: "100%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            bgcolor: "rgba(255, 255, 255, 1)",
                                            borderRadius: "16px",
                                            border: "1px solid #e1e1e1",
                                            boxShadow: "10px 15px 27px -28px #1A73E8"
                                        }}
                                    >
                                        <VChatReportSummary
                                            key={selectedRef.current.id}
                                            data={selectedRef.current}
                                        />
                                    </MDBox>
                                : 
                                    <></>
                    : 
                        <MDBox 
                            sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                bgcolor: "rgba(255, 255, 255, 1)",
                                borderRadius: "16px",
                                border: "1px solid #e1e1e1",
                                boxShadow: "10px 15px 27px -28px #1A73E8"
                            }}
                        >
                            Pilih Perusahaan untuk Mulai Menganalisa dengan AI
                        </MDBox>
                }
            </Grid>
            <ChatFaqModal
                open={chatFaqModal}
                onClose={() => setChatFaqModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                viewOnly
            />
            <ChatSupportModal
                open={chatSupportModal}
                onClose={() => setChatSupportModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                viewOnly
            />
        </Grid>
        
    )
};

export default VChat;