import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDTypography from "components/MDTypography";

const BasicInformation = ({ data }) =>{
    const user = JSON.parse(localStorage.getItem("user"));

    const [open, setOpen] = React.useState(false);
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewPath, setPreviewPath] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [selected, setSelected] = useState({});

    return (
        <>
            <Grid sx={{ 
                // mt: 5,
                mx: 3,
                pr: 5,
                width: '95%',
                height: '100%'
            }}>
                <MDTypography 
                    variant="button"  
                    fontWeight="bold" 
                    textTransform="capitalize"
                    fontSize={16}>
                    {data.name}
                </MDTypography>
                <MDTypography 
                    variant="body2" 
                    fontSize={14}
                >
                    Arrived totally in as between private. Favour of so as on pretty though elinor direct. Reasonable estimating be alteration we themselves entreaties me of reasonably. Direct wished so be expect polite valley. Whose asked stand it sense no spoil to. Prudent you too his conduct feeling limited and. Side he lose paid as hope so face upon be. Goodness did suitable learning put.
                    Consulted he eagerness unfeeling deficient existence of. Calling nothing end fertile for venture way boy. Esteem spirit temper too say adieus who direct esteem. It esteems luckily mr or picture placing drawing no. Apartments frequently or motionless on reasonable projecting expression. Way mrs end gave tall walk fact bed.
                </MDTypography>
            </Grid>
        </>
    )
};

export default BasicInformation;