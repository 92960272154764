import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import socketIO from 'socket.io-client';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import { fintechCustomerList } from '../../actions/calonPeminjamAction';

import VChat from "components/VChat";

function CreditAnalystAI() {

  const dispatch = useDispatch();

  const USER_DATA = JSON.parse(localStorage.getItem('user'));
  const TEMP_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VybmFtZSI6InBhbmppLmF0bW9qb0BnbWFpbC5jb20iLCJDb21wYW55SUQiOjEsIkNvbXBhbnlUeXBlIjoia2VudGFyYSIsIlVzZXJJRCI6MSwiUm9sZSI6WyJib3Jyb3dlciIsImxlbmRlciIsImFkbWluIl0sImV4cCI6MTcxOTM3NDY1MiwiaXNzIjoia2VudGFyYS5pbyJ9.kKlyUqviwCNdapFB06wySABT2PGoyp1xinXXOP0Kglo";
  console.log(">>> AT ", USER_DATA);

  const socket = socketIO.connect(`wss://chat.ai.dev.kentara.io?token=${USER_DATA.access_token}`, {
    withCredentials: false,
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    console.log(">>> socket ", socket)
  });

  socket.on('error', () => {
    console.log(">>> socket error ", socket)
  });

  const companyList = useSelector(
    state => {
      return state.anFintechCompanyList;
    },
    shallowEqual
  );

    useEffect(() => {
      dispatch(fintechCustomerList());
    }, []);


  return (
    <PageLayout>
      <SimpleDashboardNavbar name="Kentara AI" />
      <VChat 
        socket={socket}
        companyList={companyList} 
      />
    </PageLayout>
  );
}

export default CreditAnalystAI;
