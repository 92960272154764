import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import DescriptionIcon from '@mui/icons-material/Description';

import { fetchKYCDocs } from "../../../actions/kycPeminjamAction";
import { transformKycDocsArr } from "../../../common/Util";
import MDTypography from "components/MDTypography";
import LVKycDocument from "components/LVKycDocument";
import MDBox from "components/MDBox";


const VChatDocsList = ({ key, data }) =>{
    const user = JSON.parse(localStorage.getItem("user"));

    const [open, setOpen] = React.useState(false);
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewPath, setPreviewPath] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [selected, setSelected] = useState({});

    const listKYCDocsArray = useSelector((state) => state.listKYCDocsArray, shallowEqual);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchKYCDocs(data.id));
    }, []);

    const handleClick = () => {
        setOpen(!open)
    }

    const onApprove = ({ id, name }) => {
        setSelected({ id, name });
        setOpenApproveDialog(true);
    };

    const onReject = ({ id, name }) => {
        setSelected({ id, name });
        setOpenRejectDialog(true);
    };

    const openDocumentModal = (name, path) => {
        setPreviewTitle(name);
        setPreviewPath(path);
        setPreviewModal(true);
      }

    return (
        <>
            <Grid sx={{ 
                mt: 5,
                mx: 3,
                width: '100%',
                height: '100%' 
            }}>
                {/* <MDTypography 
                    variant="button" 
                    fontWeight="bold" 
                    textTransform="capitalize"
                    fontSize={24}>
                    Daftar Dokumen
                </MDTypography> */}
                <MDBox 
                    display="flex"
                    sx={{ 
                        width: "95%",
                        justifyContent: "flex-start" 
                    }}
                    my={2}
                >
                    <DescriptionIcon
                        fontSize="large"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                    />
                    <MDTypography 
                        variant="button" 
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={24}>
                        Daftar Dokumen
                    </MDTypography>
                </MDBox>
                <Divider />
                <LVKycDocument
                    mt={5}
                    rows={transformKycDocsArr(listKYCDocsArray)}
                    onApprove={onApprove}
                    onReject={onReject}
                    businessType={user.company.business_type}
                    onPreview={(name, path) => openDocumentModal(name, path)}

                />
            </Grid>
        </>
    )
};

export default VChatDocsList;