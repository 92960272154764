/* eslint-disable radix */
import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const fetchCalonPeminjam = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/fintech/companies/relation");
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_CALON_PEMINJAM_LIST", payload: response.data.data });
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const fintechSearchBorrower = (params) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/fintech/companies/relation/search", {
    params: {
      status: params.status,
      page: params.page,
      order_by: params.orderBy,
      order: params.order,
      limit: params.limit,
      keyword: params.keyword,
    },
  });
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_CALON_PEMINJAM_LIST", payload: response.data.data.result });
    dispatch({ type: "FETCH_CALON_PEMINJAM_PAGINATION", payload: response.data.data.pagination });
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const fetchRelationDocsFintech = (customerFintechRelationID) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get(
    `/api/v1/fintech/company/relation/history/search?customer_fintech_relation_id=${customerFintechRelationID}`
  );
  if (response !== undefined) {
    if (response.data.status === "OK") {
      dispatch({ type: "FETCH_RELATION_DOCS_FINTECH", payload: response.data.data.result });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const fintechCustomerList = (params) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/fintech/companies/customer/name", {
    params: { },
  });
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_COMPANY_LIST", payload: response.data.data });
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const approveCalonPeminjam = (params) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.put("/api/v1/fintech/company/relation/approval", params);
  if (response.data.status === "OK") {
    dispatch({
      type: "APPROVE_CALON_PEMINJAM",
      payload: { status: "APPROVE_CALON_PEMINJAM", message: params },
    });
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const rejectCalonPeminjam =
  ({ id }) =>
  async (dispatch) => {
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI.put("/api/v1/fintech/company/relation/rejection", {
      relation_id: id,
    });
    if (response.data.status === "OK") {
      dispatch({
        type: "REJECT_CALON_PEMINJAM",
        payload: { status: "REJECT_CALON_PEMINJAM", message: response.data.data },
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  };

export const approveRiwayatKerjasama = (params) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.put(
    "/api/v1/fintech/company/relation/history/approval",
    params
  );
  if (response.data.status === "OK") {
    dispatch({
      type: "APPROVE_RIWAYAT_KERJASAMA",
      payload: { status: "APPROVE_RIWAYAT_KERJASAMA", message: params },
    });
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const rejectRiwayatKerjasama = (relation_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .put("/api/v1/fintech/company/relation/history/rejection", {
      relation_id,
    })
    .catch((e) => {});
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "REJECT_RIWAYAT_KERJASAMA",
        payload: { status: "REJECT_RIWAYAT_KERJASAMA", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: {
          status: "FAIL_REJECT_RIWAYAT_KERJASAMA",
          message: "Fail to reject company relation",
        },
      });
    }
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const approveRiwayatKerjasamaAdmin = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.put("/api/v1/company/relation/history/approval", { id });
  if (response.data.status === "OK") {
    dispatch({
      type: "APPROVE_RIWAYAT_DOCUMENT",
      payload: { status: "APPROVE_RIWAYAT_DOCUMENT", message: response.data.data },
    });
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const rejectRiwayatKerjasamaAdmin = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .put("/api/v1/company/relation/history/rejection", {
      id,
    })
    .catch((e) => {});
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "REJECT_RIWAYAT_DOCUMENT",
        payload: { status: "REJECT_RIWAYAT_DOCUMENT", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: {
          status: "FAIL_REJECT_RIWAYAT_KERJASAMA",
          message: "Fail to reject company relation",
        },
      });
    }
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const createCustomerRelation = (data) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .post("/api/v1/company/customer/fintech/relation", {
      relation_id: data[0],
      transporter_shipper_relation_id: data[1],
    })
    .catch((e) => {});
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_CREATE_CUSTOMER_RELATION",
        payload: { status: "SUCCESS_CREATE_CUSTOMER_RELATION", message: response.data.data },
      });
    }
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const createCustomerRelationKyc = (fintech_id, transporter_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .post("/api/v1/admin/company/relation/dispatch", {
      fintech_id,
      transporter_id,
    })
    .catch((e) => {});
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_CREATE_CUSTOMER_RELATION",
        payload: { status: "SUCCESS_CREATE_CUSTOMER_RELATION", message: response.data.data },
      });
    }
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};
