import { useState, useRef } from "react";

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import ChatUploadAttachmentModal from "modals/chatUploadAttachmentModal";

const VChatAction =({ handleGet, handleSend, selected }) =>{
    const [chatUploadAttachmentModal, setChatUploadAttachmentModal] = useState(false);
    const [text, setChat] = useState("")

    const handleKeyDown = (e) => {
        if (e.shiftKey === true && e.keyCode === 13) {
            if (e.target.scrollHeight > 25) {
                let currentRows = rowsChat
                currentRows++
                setRows(currentRows)
            }
        } else if(e.keyCode === 13) {
            e.preventDefault()
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        try {
            if (text !== "") {
                handleSend(text)
                setChat("")
            } 
        } catch(err) {
            console.error(err)
        }
    }

    const handleOpenModalFaq = () => {
        setChatUploadAttachmentModal(true);
    };

    return (
        <MDBox
            sx={{ 
                display: "flex" 
            }}
        >   
            <MDBox 
                sx={{ 
                    width: '85%' 
                }}
            >
                <MDInput 
                    sx={{ 
                        width: '100%',
                        px:2
                    }} 
                    placeholder="Type something..."
                    value={text}
                    onKeyDown={
                        (e) => handleKeyDown(e)
                    }
                    onChange={(e) => setChat(e.target.value)} 
                
                >
                </MDInput>
            </MDBox>
            <MDBox 
                display="flex"
                alignItems="center"
                sx={{ 
                    width: '15%' 
                }}
            >
                <MDBox 
                    display="flex"
                    sx={{ 
                        width: "50%", 
                        justifyContent: "center",
                        padding: 1,
                        border: 1,
                        borderRadius: "10px",
                        borderColor: "#0D6ECD"
                    }}
                    onClick={() => handleOpenModalFaq()}
                >
                    <UploadFileRoundedIcon
                        fontSize="medium"
                        color="primary"
                    />
                </MDBox>
                &nbsp;
                <MDBox 
                    display="flex"
                    sx={{ 
                        width: "50%", 
                        justifyContent: "center",
                        padding: 1,
                        border: 1,
                        borderRadius: "10px",
                        borderColor: "#0D6ECD"
                    }}
                    onClick={handleSubmit}
                >
                    <SendIcon
                        fontSize="medium"
                        color="primary"
                    />
                </MDBox>
            </MDBox>
            <ChatUploadAttachmentModal
                open={chatUploadAttachmentModal}
                onClose={() => setChatUploadAttachmentModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                viewOnly
                roomId={selected.room_id}
            />
        </MDBox> 
    )
};

export default VChatAction;