import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDTypography from "components/MDTypography";

const CompanyInformation = ({ data }) =>{
    const user = JSON.parse(localStorage.getItem("user"));

    const [open, setOpen] = React.useState(false);
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewPath, setPreviewPath] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [selected, setSelected] = useState({});

    const MOCK_RANDOM_NAMES = [
        'Angelia Acosta',
        ' Elliott Lawrence',
        ' Ladonna Silva',
        ' Benita Diaz'
    ]

    return (
        <>
            <Grid sx={{ 
                mx: 3,
                width: '95%',
                height: '100%' 
            }}>
                <MDTypography 
                    variant="button"  
                    fontWeight="bold" 
                    textTransform="capitalize"
                    fontSize={16}>
                    Informasi Perusahaan
                </MDTypography>
                <MDTypography 
                    variant="body2" 
                    fontSize={14}
                    sx={{
                        pl: 3
                    }}
                >
                    <ul>
                        <li>Tanggal berdiri : 2008</li>
                        <li>Direksi : { MOCK_RANDOM_NAMES.join(",") } </li>
                        <li>Komisaris :  { MOCK_RANDOM_NAMES.join(",") } </li>
                    </ul>
                </MDTypography>
                
            </Grid>
        </>
    )
};

export default CompanyInformation;