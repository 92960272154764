import React, { useState, useEffect } from "react";

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const VChatCompanyList = ({ key, data, handleSelect, selected, handleSelectDocs, handleSelectReport }) =>{

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItemButton 
                onClick={handleClick}
                key={key}
                sx={{ 
                    bgcolor: selected?.id === data?.id ? "0D6ECD" : "#FFF",
                    borderStyle: selected?.id === data?.id ? "solid" : "",
                    borderWidth: selected?.id === data?.id ? "4px" : "",
                    border: selected?.id === data?.id ? 0 : "",
                    borderLeft: selected?.id === data?.id ? 7 : "",
                    borderColor: selected?.id === data?.id ? "#3498db" : "",
                    borderRadius: '',
                    marginBottom: "4px",
                    color: selected?.id === data?.id ? "#0D6ECD" : "#72737B"
                }}
            >
                <ListItemText 
                    onClick={handleSelect}
                    secondary={data.name}
                />
            </ListItemButton>
            <Collapse 
                in={selected?.id === data?.id} 
                timeout="auto" 
                unmountOnExit
            >
                <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText 
                        secondary="Daftar Dokumen" 
                        onClick={handleSelectDocs}
                    />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText 
                        secondary="Report Summary" 
                        onClick={handleSelectReport}
                    />
                </ListItemButton>
                </List>
            </Collapse>
        </>
    )
};

export default VChatCompanyList;