import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import HelpIcon from '@mui/icons-material/Help';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';

const contents = [
    {
        id: 0,
        title: "Bagaimana cara saya mengunggah dokumen untuk dianalisis?",
        message: "Anda bisa mengunggah dokumen dengan cara drag berkas ke jendela Kentara AI, atau upload di halaman Daftar Dokumen."
    },
    {
        id: 1,
        title: "Apakah AI dapat memproses dokumen dalam format yang berbeda seperti PDF, Word, atau gambar?",
        message: "Lorem ipsum dolor sit amet"
    },
    {
        id: 2,
        title: "Bagaimana cara AI mengekstrak informasi penting dari dokumen?",
        message: "Lorem ipsum dolor sit amet"
    },
    {
        id: 3,
        title: "Apakah AI dapat mengidentifikasi kesalahan atau ketidakakuratan dalam dokumen?",
        message: "Lorem ipsum dolor sit amet"
    },
    {
        id: 4,
        title: "Bagaimana tingkat keakuratan AI dalam menganalisis dokumen?",
        message: "Lorem ipsum dolor sit amet"
    },
    {
        id: 5,
        title: "Bagaimana cara saya mengunggah dokumen untuk dianalisis?",
        message: "Lorem ipsum dolor sit amet"
    },
    {
        id: 6,
        title: "Apakah AI dapat memproses dokumen dalam format yang berbeda seperti PDF, Word, atau gambar?",
        message: "Lorem ipsum dolor sit amet"
    },
]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 300,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    minWidth: '55%',
    height: '60%',
    overflowY: 'auto'
  };

const ChatSupportModal = (props) => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

    return(
        <Modal
            {...props}
        >
            <MDBox sx={style}>
                <MDBox 
                    display="flex"
                    sx={{ width: "100%", justifyContent: "flex-start" }}
                    my={2}
                >
                    <HelpIcon
                        fontSize="large"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                    />
                    <MDTypography 
                        variant="button" 
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={24}>
                        Bantuan
                    </MDTypography>
                </MDBox>
                {
                    contents 
                    ? 
                        contents.map((item) => {
                            return (
                                <Accordion
                                    expanded={expanded === item.id}
                                    onChange={handleChange(item.id)}
                                    elevation={0}
                                >
                                    <AccordionSummary
                                    expandIcon={<ArrowDownwardIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    >
                                    <Typography>{item.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        {item.message}
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) 
                    : <></>
                }
            </MDBox>
        </Modal>
    );
}

export default ChatSupportModal;