import { useEffect, useRef } from "react";
import moment from "moment";

import Button from '@mui/material/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import MDBox from "components/MDBox";

const VChatMessageItem =({ item, key, chatHistory, handleSendAction }) =>{
    const KENTARA_ID = 0

    const messagesEndRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 200)
    }, [chatHistory?.length])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const handleClickButton = async (value) => {
        handleSendAction(value)
    }

    return (
        <>
            <MDBox 
                sx={{ 
                    display: "flex",
                    width: "auto",
                    mb: 2,
                    justifyContent: item?.user_id === KENTARA_ID ? "" : "flex-end"
                }}
                ref={chatHistory?.[chatHistory?.length - 1] ? messagesEndRef : null} 
            >
                <MDBox
                    sx={{
                        display: "inline",
                        verticalAlign: "middle",
                        px: 1
                    }}
                >
                    {/* {
                        item?.user_id === KENTARA_ID && (
                            <AccountCircleOutlinedIcon 
                                fontSize="large"
                                color="primary"
                            />
                        )
                    } */}
                    <MDBox 
                        sx={{ 
                            mx: 1,
                            pl: 1,
                            py: 1,
                            bgcolor: "#F4F8FC",
                            borderRadius: "8px",
                            border: "1px solid #e1e1e1",
                            fontSize: "small",
                            display: "inline-block"
                        }}
                    >
                        <MDBox
                            sx={{
                                px: 2,
                                width: "auto",
                                // maxWidth: "50%",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: item.content ? item.content : item.message
                            }} 
                        />
                        <MDBox
                            sx={{
                                marginLeft: 2
                            }}
                        >
                        {
                            item.action ? 
                            (
                                item.action.map((obj, i) => {
                                    if (obj.type === "button") {
                                        return (<Button 
                                            sx={{
                                                marginTop: 1,
                                                marginRight: 1
                                            }}
                                            variant="contained"
                                            value={obj.value}
                                            onClick={() => handleClickButton(obj.value)}

                                        >
                                            {obj.label}
                                        </Button>)
                                    }
                                })
                            ) : <></>
                        }
                        </MDBox>
                        <MDBox
                            sx={{
                                fontSize: 10,
                                color: "grey",
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: 1
                            }}
                        >
                            {moment(item.created_at).format("DD MMM YYYY HH:mm")}
                        </MDBox>
                    </MDBox>

                    {/* {
                        item?.user_id !== KENTARA_ID && (
                            <AccountCircleOutlinedIcon 
                                fontSize="large"
                                color="primary"
                            />
                        )
                    } */}
                </MDBox>
            </MDBox>
        </>
    )
};

export default VChatMessageItem;