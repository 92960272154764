/* export default {
    company: 'https://company.dev.kentara.io',
    invoice: 'https://invoice.dev.kentara.io',
    financing: 'https://financing.dev.kentara.io'
}; */

export default {
  authentication: process.env.REACT_APP_SERVER_AUTHENTICATION,
  company: process.env.REACT_APP_SERVER_COMPANY,
  invoice: process.env.REACT_APP_SERVER_INVOICE,
  financing: process.env.REACT_APP_SERVER_FINANCING,
  payment: process.env.REACT_APP_SERVER_PAYMENT,
  sales: process.env.REACT_APP_SERVER_SALES,
  user: process.env.REACT_APP_SERVER_USER,
  ai: process.env.REACT_APP_SERVER_AI,
};
