import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import {
    saveCompanyDocument
  } from "../actions/companyAction";
import { Box } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '50vh',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

const ChatUploadAttachmentModal = (props) => {
    const dispatch = useDispatch();
    
    const fileInputRef = useRef(null);
    
    const message = useSelector((state) => state.message, shallowEqual);

    const [imageList, setImageList] = useState([]);
    const [bucketImagePreSave, setBucketImagePreSave] = useState([]);
    const [imagePrev, setImagePrev] = useState(null);
    const [disable, setDisable] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [isMessage, setIsMessage] = useState(null);
    
    const STRICT_IMAGE_MAX_SIZE = 50000000; 
    const STRICT_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
    
    useEffect(() => {
        if (imagePrev !== null) {
            if (!imageList.includes(imagePrev)){
                setImageList([...imageList, imagePrev])
                setImagePrev(null)
            }
        }
    })

    useEffect(() => {
        switch (message.status) {
          case "SUCCESS_SAVE_DOCUMENT_CLASSIFICATION":
            setOpenLoader(false);
            setImageList([]);
            setImagePrev(null);
            setIsMessage("Dokumen berhasil terupload! Silahkan kembali ke chat untuk kembali berinteraksi dengan Kentara AI.");
          default:
        }
      }, [message]);

    const handleSubmit = async () => {
        try {
            setOpenLoader(true);
            imageList.map((item) => {
                dispatch(saveCompanyDocument({ assetFile: item.data, roomId: props.roomId }));
            })
        } catch(err) {
            console.error(err);
            setOpenLoader(false);
            setImageList([]);
            setImagePrev(null);
            setIsMessage("Ops! Ada kendala di sistem. Mohon ulangi upload dokumen");
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
          (fileInputRef.current).click();
        }
    }

    const handleDelete = async (id) => {
        // handle on delete image / cancel image 
    }

    const onInput = (e) => {
        console.log(">>> get e ", e);
        if (!e || e === undefined) {
            return
        }

        // sanitizing file name
        const fileName = e.name.split(".");
        const replaceSpecialChar = fileName[0].replace(/[^a-zA-Z0-9]/g,'_');
        const fileNameSanitized = `${replaceSpecialChar}.${fileName[fileName.length-1]}`;


        if(!disable) {
            const reader = new FileReader()
            reader.readAsDataURL(e)
            reader.onload = async (data) => {
                setImagePrev({data: e, base: data?.target?.result})
                // handle on custom method
            }
        }
    };

    return(
        <Modal
            {...props}
        >
            <MDBox sx={style}>
                <MDBox 
                    display="flex"
                    sx={{ width: "100%", justifyContent: "flex-start" }}
                    my={2}
                >
                    <UploadFileRoundedIcon
                        fontSize="large"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                    />
                    <MDTypography 
                        variant="button" 
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={24}>
                        Upload Dokumen
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    {imageList.length ? imageList.map((item, i) => (
                        
                            <MDBox 
                                key={i} 
                                sx={{
                                    border: "2px dashed",
                                    borderColor: "#FFC47B",
                                    borderRadius: "10px",
                                    marginBottom: "3px",
                                    marginRight: "3px",
                                    width: "100px",
                                    height: "100px"
                                }}
                            >
                                <MDBox 
                                    onClick={() => handleDelete(item.data.lastModified)}
                                    sx={{
                                        position: "relative",
                                        width: "100px",
                                        height: "100px"
                                    }}
                                >
                                    <Box    
                                        component="img"
                                        src={STRICT_IMAGE_MIME_TYPES.includes(item.data.type) ? item.base : "/file.svg"} 
                                        alt='' 
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "object-cover",
                                            borderRadius: "10px",
                                            borderColor: "#FFC47B",

                                        }}
                                    />
                                    <MDBox
                                        sx={{
                                            position: "absolute",
                                            top: 2,
                                            left: 2,
                                            borderRadius: "5px",
                                            padding: 1,
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                            color: "red"
                                        }}
                                    >
                                        {item.data.name}
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                    )) : <></>}
                    <MDBox 
                        onClick={handleButtonClick}
                        sx={{
                            position: "relative",
                            border: "2px dashed",
                            borderColor: "#FFC47B",
                            borderRadius: "10px",
                            marginBottom: "3px",
                            marginRight: "3px",
                            width: "100px",
                            height: "100px"
                        }}
                    >
                        <div className='bg-[#ffe1bd] mx-auto my-auto rounded-2xl px-5 py-3'>
                            <input hidden type='file' accept="*" ref={fileInputRef} name="img" id='file' onChange={(e) => {
                                if(e.target.files && e.target.files.length > 0) {
                                    onInput(e.target.files[0])}}
                                }
                            />
                            <AddIcon
                                fontSize="medium"
                                color="primary"
                                sx={{
                                    position: "absolute",
                                    backgroundColor: "#FFC47B",
                                    borderRadius: "5px"
                                }}
                            />
                        </div>
                    </MDBox>
                </MDBox>
                {
                    isMessage ? 
                    (
                        <MDTypography 
                            variant="button" 
                            fontWeight="bold" 
                            textTransform="capitalize"
                            fontSize={14}>
                            {isMessage}
                        </MDTypography>
                    ) : <></>
                }
                <Button 
                    sx={{
                        marginTop: "10px"
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoader}
                    // onClick={handleCloseLoader}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </MDBox>
        </Modal>
    );
}

export default ChatUploadAttachmentModal;