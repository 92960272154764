import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const BusinessOverview = ({ data }) =>{
    const user = JSON.parse(localStorage.getItem("user"));

    const [open, setOpen] = React.useState(false);
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewPath, setPreviewPath] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [selected, setSelected] = useState({});

    return (
        <>
            <Grid sx={{ 
                mx: 3,
                width: '95%',
                height: '100%',
            }}>
                <MDTypography 
                    variant="button"  
                    fontWeight="bold" 
                    textTransform="capitalize"
                    fontSize={16}>
                    Kondisi Bisnis
                </MDTypography>
                <MDBox
                    sx={{
                        width: '50%'
                    }}
                >
                    <Bar
                        data={
                            {
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun'],
                                datasets:[
                                    {
                                    label:'Cashflow Bulanan',
                                    data:[
                                        3500000,
                                        1250000,
                                        500000,
                                        3145000,
                                        350000,
                                        1240000
                                    ],
                                    backgroundColor:[
                                        'rgba(255, 99, 132, 0.6)',
                                        'rgba(54, 162, 235, 0.6)',
                                        'rgba(255, 206, 86, 0.6)',
                                        'rgba(75, 192, 192, 0.6)',
                                        'rgba(153, 102, 255, 0.6)',
                                        'rgba(255, 159, 64, 0.6)',
                                        'rgba(255, 99, 132, 0.6)'
                                    ]
                                    }
                                ]
                            }
                        }
                        options={{
                            title:{
                            display: true,
                            text:'Largest Cities In X',
                            fontSize:25
                            },
                            legend:{
                            display: true,
                            position: 'right'
                            }
                        }}
                    />    
                </MDBox>
            </Grid>
        </>
    )
};

export default BusinessOverview;