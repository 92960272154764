export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_LIST':
      return action.payload;
    case 'INIT_FINANCING_LIST':
      return state = [];
    default:
      return state;
  }

};