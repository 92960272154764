import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const FinancialInformation = ({ data }) =>{
    const user = JSON.parse(localStorage.getItem("user"));

    const card = (
        <React.Fragment>
          <CardContent 
            sx={{
              backgroundColor: '#2980b9',
              borderRadius: '10px'
            }}
          >
            <MDTypography 
              variant="h5" 
              component="div" 
              color="white"
              fontSize={14}
            >
              Angelia Acosta
            </MDTypography>
            <MDTypography 
              variant="body2" 
              color="white"
              fontSize={14}
            >
              Status Kredit : Lancar 
            </MDTypography>
          </CardContent>
        </React.Fragment>
    );
    
    return (
        <>
            <Grid sx={{ 
                mx: 3,
                width: '95%',
                height: '100%' 
            }}>
                 <MDTypography 
                    variant="button"  
                    fontWeight="bold" 
                    textTransform="capitalize"
                    fontSize={16}>
                    Informasi Finansial
                </MDTypography>
                <MDBox
                  sx={{
                    mb: 2
                  }}
                >
                    <MDTypography 
                        variant="button"  
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={14}>
                        Direksi
                    </MDTypography>
                    <MDBox
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <MDBox
                      sx={{
                        width: '200px',
                        mr: 2,
                      }}
                    >
                        <Card variant="outlined">{card}</Card>
                      </MDBox>
                      <MDBox
                        sx={{
                          width: '200px'
                        }}
                      >
                        <Card variant="outlined">{card}</Card>
                      </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox
                  sx={{
                    mb: 2
                  }}
                >
                    <MDTypography 
                        variant="button"  
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={14}>
                        Komisaris
                    </MDTypography>
                    <MDBox
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <MDBox
                      sx={{
                        width: '200px',
                        mr: 2,
                      }}
                    >
                        <Card variant="outlined">{card}</Card>
                      </MDBox>
                      <MDBox
                        sx={{
                          width: '200px'
                        }}
                      >
                        <Card variant="outlined">{card}</Card>
                      </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox>
                    <MDTypography 
                        variant="button"  
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={14}>
                        Perusahaan
                    </MDTypography>
                    <MDBox
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <MDBox
                      sx={{
                        width: '200px',
                        mr: 2,
                      }}
                    >
                        <Card variant="outlined">{card}</Card>
                      </MDBox>
                      <MDBox
                        sx={{
                          width: '200px'
                        }}
                      >
                        <Card variant="outlined">{card}</Card>
                      </MDBox>
                    </MDBox>
                </MDBox>
            </Grid>
        </>
    )
};

export default FinancialInformation;