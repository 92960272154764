import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { fetchKYCDocs } from "../../../actions/kycPeminjamAction";
import { transformKycDocsArr } from "../../../common/Util";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import LVKycDocument from "components/LVKycDocument";

import BasicInformation from "./Sections/BasicInformation";
import CompanyInformation from "./Sections/CompanyInformation";
import FinancialInformation from "./Sections/FinancialInformation";
import BusinessOverview from "./Sections/BusinessOverview";

const VChatReportSummary = ({ key, data }) =>{
    const user = JSON.parse(localStorage.getItem("user"));

    const [open, setOpen] = React.useState(false);
    const [openApproveDialog, setOpenApproveDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewPath, setPreviewPath] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [selected, setSelected] = useState({});

    const listKYCDocsArray = useSelector((state) => state.listKYCDocsArray, shallowEqual);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchKYCDocs(data.id));
    }, []);

    const handleClick = () => {
        setOpen(!open)
    }

    const onApprove = ({ id, name }) => {
        setSelected({ id, name });
        setOpenApproveDialog(true);
    };

    const onReject = ({ id, name }) => {
        setSelected({ id, name });
        setOpenRejectDialog(true);
    };

    const openDocumentModal = (name, path) => {
        setPreviewTitle(name);
        setPreviewPath(path);
        setPreviewModal(true);
      }

    return (
        <>
            <Grid sx={{ 
                mt: 5,
                mx: 3,
                width: '100%',
                height: "80vh",
                overflowY: 'auto',
                overflowX: 'hidden'
            }}>
                <MDBox 
                    display="flex"
                    sx={{ 
                        width: "95%",
                        justifyContent: "flex-start" 
                    }}
                    my={2}
                >
                    <AssessmentIcon
                        fontSize="large"
                        color="primary"
                        sx={{ marginRight: "10px" }}
                    />
                    <MDTypography 
                        variant="button" 
                        fontWeight="bold" 
                        textTransform="capitalize"
                        fontSize={24}>
                        Report Summary
                    </MDTypography>
                </MDBox>
                <Divider />
                <MDBox>
                    <BasicInformation data={data} />
                </MDBox>
                <Divider />
                <MDBox>
                    <CompanyInformation data={data} />
                </MDBox>
                <Divider />
                <MDBox>
                    <FinancialInformation data={data} />
                </MDBox>
                <Divider />
                <MDBox>
                    <BusinessOverview data={data} />
                </MDBox>
                <Divider />
            </Grid>
        </>
    )
};

export default VChatReportSummary;